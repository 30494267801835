import React, { Component } from 'react';
import ReactPlayer from "react-player";

const videoPlayerStyle = {
    marginBottom: "25px",
    marginTop: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#000"
}

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.zoomFactor = 0.8; // Adjust zoom level
        this.videoWidth = 960;
        this.videoHeight = 540;
        this.MAGNIFIER_SIZE = 200;
        this.state = {
            currentVideo: props.url,
            boundingBoxes: {},
            dataLoaded: false
        };
        this.videoRef = React.createRef();
        this.canvasRef = React.createRef();
        this.canvasRefTop = React.createRef();
        this.magnifierCanvasRef = React.createRef();
    }

    componentDidMount() {
        this.loadBoundingBoxData();
        // Give it some time to load        
        setTimeout(() => {
            if (this.videoRef.current) {
                this.setupMagnifier();
            }
        }, 1000);
    }

    loadBoundingBoxData = () => {
        fetch('demo-bounding-box.txt')
            .then(response => response.text())
            .then(text => this.parseBoundingBoxData(text))
            .catch(error => console.error('Error loading bounding box data:', error));
    };

    parseBoundingBoxData = (text) => {
        const boundingBoxes = {};
        const lines = text.split('\n');
        lines.forEach(line => {
            if (line.trim() === '') return;
            const parts = line.trim().split(' ');
            if (parts.length >= 6) {
                const frameNumber = parseInt(parts[0]);
                const x_center = parseFloat(parts[2]);
                const y_center = parseFloat(parts[3]);
                const width = parseFloat(parts[4]);
                const height = parseFloat(parts[5]);

                if (!boundingBoxes[frameNumber]) {
                    boundingBoxes[frameNumber] = [];
                }
                boundingBoxes[frameNumber].push({ x_center, y_center, width, height });
            }
        });
        this.setState({ boundingBoxes, dataLoaded: true }, this.startDrawing);
    };

    startDrawing = () => {
        const video = this.videoRef.current?.getInternalPlayer();
        console.log("videooo   ", video);
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');

        video.addEventListener('play', () => {
            const draw = () => {
                if (video.paused || video.ended) return;
                this.drawBoundingBoxes(ctx, video);
                //draw canvas on the side of the video
                requestAnimationFrame(draw);
            };
            draw();
        });
    };

    drawBoundingBoxes = (ctx, video) => {
        const { boundingBoxes } = this.state;
        const frameRate = 20;
        const currentFrame = Math.floor(video.currentTime * frameRate);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        const boxes = boundingBoxes[currentFrame] || [];
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 1;
        const canvasTop = this.canvasRefTop.current;
        const ctxCanvasTop = canvasTop.getContext('2d');
        ctxCanvasTop.clearRect(0, 0, this.MAGNIFIER_SIZE, this.MAGNIFIER_SIZE);

        boxes.forEach(box => {
            // Draw bounding box on the main canvas

            ctx.strokeRect(
                (box.x_center - box.width / 2) * ctx.canvas.width,
                (box.y_center - box.height / 2) * ctx.canvas.height,
                box.width * ctx.canvas.width,
                box.height * ctx.canvas.height
            );

            // Compute the CENTER of the bounding box in video dimensions
            const centerX = box.x_center * video.videoWidth;
            const centerY = box.y_center * video.videoHeight;
            const sx = Math.max(0, centerX - (this.MAGNIFIER_SIZE / 2) * this.zoomFactor);
            const sy = Math.max(0, centerY - (this.MAGNIFIER_SIZE / 2) * this.zoomFactor);
            const sWidth = 100 / this.zoomFactor;
            const sHeight = 100 / this.zoomFactor;
            ctxCanvasTop.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, this.MAGNIFIER_SIZE, this.MAGNIFIER_SIZE);
        });
    };

    setupMagnifier = () => {
        const video = this.videoRef.current?.getInternalPlayer();
        const magnifierCanvas = this.magnifierCanvasRef.current;
        const magnifierCtx = magnifierCanvas.getContext('2d');

        video.addEventListener('mousemove', (event) => {
            const rect = video.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            // Position the magnifier
            magnifierCanvas.style.display = 'block';
            magnifierCanvas.style.left = `${x}px`;
            magnifierCanvas.style.top = `${y}px`;

            // Draw the magnified portion
            const sx = Math.max(0, x * (video.videoWidth / this.videoWidth) - 25 / this.zoomFactor);
            const sy = Math.max(0, y * (video.videoHeight / this.videoHeight) - 25 / this.zoomFactor);
            const sWidth = 100 / this.zoomFactor;
            const sHeight = 100 / this.zoomFactor;

            const tempCanvas = document.createElement('canvas');
            const tempCtx = tempCanvas.getContext('2d');
            tempCanvas.width = video.videoWidth;
            tempCanvas.height = video.videoHeight;

            tempCtx.drawImage(video, 0, 0, tempCanvas.width, tempCanvas.height);
            magnifierCtx.clearRect(0, 0, this.MAGNIFIER_SIZE, this.MAGNIFIER_SIZE);
            magnifierCtx.drawImage(tempCanvas, sx, sy, sWidth, sHeight, 0, 0, this.MAGNIFIER_SIZE, this.MAGNIFIER_SIZE);
        });

        video.addEventListener('mouseout', () => {
            magnifierCanvas.style.display = 'none';
        });
    };

    render() {
        const { currentVideo } = this.state;

        return (
            <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                        {currentVideo && (
                            <div className="video-container" style={{ position: 'relative', width: '960px', height: '540px' }}>
                                <ReactPlayer ref={this.videoRef} url={currentVideo} width="100%" height="100%" style={videoPlayerStyle} controls={true} />
                                <canvas
                                    ref={this.canvasRef}
                                    width={this.videoWidth}
                                    height={this.videoHeight}
                                    style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
                                ></canvas>
                                <canvas
                                    ref={this.magnifierCanvasRef}
                                    width={this.MAGNIFIER_SIZE}
                                    height={this.MAGNIFIER_SIZE}
                                    style={{ position: 'absolute', border: '1px solid black', display: 'none', pointerEvents: 'none', backgroundColor: 'black' }}
                                ></canvas>
                            </div>
                        )}
                    </div>
                    <div style={{ width: this.videoWidth, height: '200px', margin: "20px" }}>
                        <br />
                        <br />
                        <canvas style={{ width: '200', height: '200' }} ref={this.canvasRefTop}></canvas>
                    </div>
                </div>
            </div>

        );
    }
}

export default VideoPlayer;
